<template>
  <div class="content">
    <PageHeader title="Templates Email" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-lg-4 col-md-4 m-top-10">
                  <input
                    class="form-control"
                    :placeholder="`${$t('generic-str.search-by.default')}...`"
                    v-model="form.name.lk"
                  />
                </div>
                <div class="col-lg-2 col-md-2 m-top-10">
                  <div class="form-group">
                    <div class="input-group">
                      <div class>
                        <button class="btn btn-danger" @click="fetch(1)">
                          {{$t('generic-str.filter')}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6  m-top-10 text-right">
                  <div class="to-right">
                    <button
                      @click="createPage()"
                      class="btn btn-success hide"
                    >
                      <i class="icon dripicons-plus"></i>{{$t('app.create')}}
                    </button>
                      <button
                      v-modal="{
                        target: 'libary-modal',
                        data: {
                          isLib: false,
                        }
                      }"
                      class="btn btn-success"
                    >
                      <i class="icon dripicons-plus"></i>{{$t('app.create')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="row" style="padding: 30px;" v-if="fetched">
                <div v-for="(template, i) in templates" :key="template.id" class="slate col-sm-4 col-md-3 col-lg-2 esdev-mb15 preview-template-wrapper ng-star-inserted">
                  <div class="template-content">
                    <router-link
                      :to="`templates/create/${template.id}/false`"
                      class="template-image cursor-pointer"
                      v-tooltip.top="template.name"
                      :id="'template-id-' + i"
                    >
                      <img v-if="template.preview_url" class="img-responsive ng-star-inserted" :src="`${template.preview_url}`">
                      <img v-else class="img-responsive ng-star-inserted" src="/assets/img/empty.png">
                    </router-link>
                    <div class="one-info">
                      <div class="template-name">
                        {{template.name}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="responsive-table">
                <div
                  v-if="!templates.length && fetched"
                  class="text-center table-placeholder"
                >
                  <i
                    class="fas fa-envelope font-size-80"
                    v-tooltip.top="$t('template-component.tooltip')"
                  ></i>
                  <h5 class="card-title m-t-20">{{$t('sms.templates.none')}}</h5>
                  <p>{{$t('sms.templates.lbl-register')}}</p>
                </div>
                <div
                  v-if="!fetched"
                  class="qt-block-ui relative"
                  style="padding: 120px; margin: 0;"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <create-template-modal id="create-template" @submit="fetch(1)" />
    <template-modal id="template-modal" @submit="fetch(1)" />
    <library-modal id="libary-modal" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import CreateTemplateModal from '@/components/mails/CreateTemplateModal.vue';
import TemplateModal from '@/components/mails/TemplateModal.vue';
import MailService from '@/services/mail.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';
import LibraryModal from '@/components/mails/LibraryModal.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    CreateTemplateModal,
    TemplateModal,
    Pagination,
    LibraryModal,
  },
  data() {
    return {
      isSending: false,
      fetched: false,
      templates: [],
      form: {
        page: 1,
        name: {
          lk: '',
        },
      },
      pages: 1,
      selectedTemplates: [],
    };
  },
  computed: {
    selectAllTemplates: {
      get() {
        if (this.templates) {
          return this.selectedTemplates.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.templates.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedTemplates = selected;
      },
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    createPage() {
      this.$router.push('/mail/templates/create');
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      MailService.getTemplates(this.form).then(
        (response) => {
          this.fetched = true;
          this.templates = response.data;
          this.pages = response.last_page;
          console.log(response);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(template) {
      Swal.fire({
        title: `${this.$t('template-component.remove-2')} "${template.name}"?`,
        text: this.$t('template-component.warning-2'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          MailService.deleteTemplate(template.id).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.loaded = true;
              this.fetch(1);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('template-component.deleted'),
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('template-component.remove'),
        text: this.$t('template-component.remove-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedTemplates.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          MailService.deleteTemplates({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('template-component.deleted'),
                type: 'success',
              });
              this.fetch(1);
              this.isSending = false;
              this.selectedTemplates = [];
            },
            (error) => {
              console.log(error);
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}
.template-image {
  height: 0;
  padding-bottom: 75%;
  display: inline-block;
  overflow: hidden;
  z-index: 0;
  position: relative;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 0 1px #ddd;
  width: 100%;
  background: #f5f5f5;
  vertical-align: top;
  margin-bottom: -5px;
  z-index: 0;
}
.one-info{
  box-shadow: 0 0 0 1px #ddd;
  border-radius: 0 0 10px 10px;
  padding: 12px 10px 0;
  background-color: #fff;
}
.template-image img{
  border-radius: 10px 10px 10px 10px;
}
.template-image:hover{
  overflow: visible;
  z-index: 1;
}

.template-content img:hover{
  border: solid 2px #635ebe;
}
.template-content{
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 30px;
}
.cursor-pointer {
  cursor: pointer;
}

.template-name{
  margin-top: 0px;
}
.template-name {
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
  display: inline-block;
  width: 95%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
